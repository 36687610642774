
<template>
    <Layout :show-admin="showAdmin" :show-super-admin="showSuperAdmin" :show-super-sales="showSuperSales">
        <PageHeader :title="title" :items="items" />

        <div class="row mb-4">
            <div class="col-lg-4 col-md-3">
                <div class="card h-100">
                    <div class="card-body">
                        <div class="text-center">
                            <b-dropdown class="float-end" variant="white" right menu-class="dropdown-menu-end" toggle-class="font-size-16 text-body p-0">
                                <template v-slot:button-content>
                                    <i class="uil uil-ellipsis-v"></i>
                                </template>
                                <button class="dropdown-item" v-b-modal.modal-update>Modifier</button>
                                <button class="dropdown-item" @click="onDeleteCustomer(customer.id)">Supprimer</button>
                            </b-dropdown>
                            <EditCustomer v-if="customer" @customer-updated="onCustomerUpdated" :customer="customer" :show-admin="showAdmin" :show-super-sales="showSuperSales" />
                        </div>

                        <div class="text-muted">
                            <h5 v-if="customerLoaded" class="font-size-16">
                                <i class="bx bxs-star me-2" @click="onToggleCustomerFlag" v-if="customer.flag"></i>
                                <i class="bx bx-star me-2" @click="onToggleCustomerFlag" v-else></i>
                                {{ customer.firstname }} {{ customer.lastname }} 
                                <span 
                                    class="ms-lg-2 badge font-size-12"
                                    :class="[`bg-${CustomerService.getStatusColor(customer.status)}`]"
                                >{{ customer.status }}</span>
                            </h5>
                            <p class="placeholder-glow"><span v-if="!customerLoaded" class="placeholder col-6"></span></p>
                            <div class="table-responsive mt-4 mb-0">
                                <div>
                                    <p class="mb-1">Téléphone :</p>
                                    <h5 class="font-size-16" v-if="customerLoaded"><a :href="'tel:' + customer.phone">{{ customer.phone }}</a></h5>
                                    <p class="placeholder-glow"><span v-if="!customerLoaded" class="placeholder col-4"></span></p>
                                </div>
                                <div class="mt-4" v-if="customer.mobile && customer.mobile != ''">
                                    <p class="mb-1">Téléphone portable :</p>
                                    <h5 class="font-size-16" v-if="customerLoaded"><a :href="'tel:' + customer.mobile">{{ customer.mobile }}</a></h5>
                                    <p class="placeholder-glow"><span v-if="!customerLoaded" class="placeholder col-4"></span></p>
                                </div>
                                <div class="mt-4">
                                    <p class="mb-1">E-mail :</p>
                                    <h5 class="font-size-16" v-if="customerLoaded"><a :href="'mailto:' + customer.email">{{ customer.email }}</a></h5>
                                    <p class="placeholder-glow"><span v-if="!customerLoaded" class="placeholder col-6"></span></p>
                                </div>
                                <div class="mt-4">
                                    <p class="mb-1">Adresse :</p>
                                    <h5 class="font-size-16" v-if="customerLoaded">{{ customer.address }}<br>{{ customer.postcode }} {{ customer.city }}</h5>
                                    <p class="placeholder-glow"><span v-if="!customerLoaded" class="placeholder col-8"></span></p>
                                </div>
                                <div class="mt-4">
                                    <p class="mb-1">Date de création :</p>
                                    <h5 class="font-size-16" v-if="customerLoaded">{{ customer.created_date }}</h5>
                                    <p class="placeholder-glow"><span v-if="!customerLoaded" class="placeholder col-8"></span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-8 col-md-9">
                <div class="card mb-0 p-4">
                    <div>
                        <h5 class="font-size-16 mb-4"><i class="uil uil-clipboard-notes font-size-20 me-2"></i> Ventes</h5>

                        <div class="table-responsive">
                            <table class="table table-nowrap table-hover mb-0">
                                <thead>
                                    <tr>
                                        <th>N°</th>
                                        <th scope="col">Date</th>
                                        <th scope="col">Total</th>
                                        <th scope="col">Status</th>
                                        <th scope="col" style="width: 120px;">Action</th>
                                    </tr>
                                </thead>
                                <tbody v-if="customer.sales">
                                    <tr v-for="sell in customer.sales" :key="sell.id">
                                        <th scope="row">{{ SellService.getNumber(sell.sellId, sell.created_date, sell.company) }}</th>
                                        <td>{{ SellService.getDate(sell.created_date) }}</td>
                                        <td>{{ sell.total | price }}</td>
                                        <td>
                                            <span 
                                                class="badge font-size-12"
                                                :class="[`bg-${SellService.getStatusColor(sell.status)}`]"
                                            >{{ sell.status }}</span>
                                        </td>
                                        <td>
                                            <router-link :to="'/sell/' + sell.id" class="px-2 text-primary" content="Modifier" v-tippy>
                                                <i class="uil uil-pen font-size-18"></i>
                                            </router-link>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="card mt-2 mb-0 p-4">
                    <div>
                        <h5 class="font-size-16 mb-4"><i class="uil uil-clipboard-notes font-size-20 me-2"></i> Rendez-vous</h5>

                        <div class="table-responsive">
                            <table class="table table-nowrap table-hover mb-0">
                                <thead>
                                    <tr>
                                        <th scope="col">Date</th>
                                        <th scope="col">Commercial</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Commentaire</th>
                                    </tr>
                                </thead>
                                <tbody v-if="customer.appointments">
                                    <tr v-for="appointment in customer.appointments" :key="appointment.id">
                                        <td>{{ SellService.getDate(appointment.createdDate) }}</td>
                                        <td>{{ appointment.user.firstname }} {{ appointment.user.lastname }}</td>
                                        <td>
                                            <span 
                                                class="badge font-size-12 bg-primary"
                                            >{{ appointment.status }}</span>
                                        </td>
                                        <td>{{ appointment.comment }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="card mt-2 mb-0 p-4">
                    <div class="row">
                        <div class="col-12">
                            <label for="title">Commentaire interne</label>
                            <textarea
                                v-model="customer.comment"
                                class="form-control"
                                :maxlength="225"
                                placeholder="Entrer un commentaire"
                                rows="3"
                                style="white-space: pre-wrap;"
                            ></textarea>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-12 text-end">
                            <button class="btn btn-success" @click.prevent="onUpdateCustomerComment()">
                                Enregistrer
                                <i class="mdi mdi-floppy ms-2"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
    import Swal from "sweetalert2";
    import Layout from "@/views/layouts/main";
    import Utilities from "@/services/utilities";
    import SellService from "@/services/sell.service";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import api from '@/api/api';
    import Customer from '@/models/customer';
    import EditCustomer from "@/forms/edit-customer";
    import CustomerService from "@/services/customer.service";

    export default {
        components: { Layout, PageHeader, EditCustomer },
        page: {
            title: "Client",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
        data() {
            return {
                CustomerService,
                Utilities,
                SellService,
                title: "Client",
                items: [
                    {
                        text: "Accueil",
                        to: "/"
                    },
                    {
                        text: "Clients",
                        to: "/customers"
                    },
                    {
                        text: "Client",
                        active: true,
                    },
                ],
                customerLoaded: false,
                customer: new Customer('', '', '', '', '', '', '', '', 'En cours', '', false),
            };
        },
        mounted() {
            if (!this.$props.showSuperAdmin && !this.$props.showAdmin && !this.$props.showSuperSales) {
                this.$router.push('/');
            }
            if (this.$route.params.id) {
                this.loadCustomer(this.$route.params.id);
            }
        },
        props: ["showAdmin", "showSuperAdmin", "showSuperSales"],
        methods: {
            loadCustomer(id) {
                api
                    .get('/customers/' + id)
                    .then(
                        (response) => {
                            if (response && response.status == 200) {
                                this.customer = response.data;
                                this.customerLoaded = true;
                            }
                        },
                        () => {
                            Utilities.errormsg();
                            this.customerLoaded = true;
                        }
                    )
            },
            onDeleteCustomer(id) {
                Swal.fire({
                    title: "Attention",
                    text: "Êtes-vous sûr de vouloir supprimer ce client ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Supprimer",
                    cancelButtonText: "Annuler",
                }).then((result) => {
                    if (result.value) {
                    api
                        .delete('/customers/' + id)
                        .then(
                            (response) => {
                                if (response && response.status == 204) {
                                    Swal.fire({
                                    title: "Succès",
                                    text: "Le client a bien été supprimé !",
                                    timer: 1500,
                                    icon: "success",
                                    confirmButtonColor: "#2e58a6",
                                    confirmButtonText: "Fermer",
                                    });
                                    this.$router.push('/customers');
                                }
                            },
                            () => {
                                Utilities.errormsg();
                            }
                        )
                    }
                });
            },
            closeModal() {
                this.$bvModal.hide('modal-update');
            },
            onCustomerUpdated() {
                this.closeModal();
                this.loadCustomer(this.$route.params.id);
            },
            onToggleCustomerFlag() {
                this.customer.flag = !this.customer.flag;
                api
                    .patch('/customers/' + this.customer.id, 
                    {
                        flag: this.customer.flag
                    }, 
                    {
                        headers: {
                            'Content-Type': 'application/merge-patch+json'
                        }
                    })
                    .then(
                        (response) => {
                            if (response && response.status == 200) {
                                this.$emit('customer-updated');
                            }
                        },
                        () => {
                            Utilities.errormsg();
                        }
                    )
            },
            onUpdateCustomerComment: function () {
                api
                    .patch('/customers/' + this.customer.id, 
                    {
                        comment: this.customer.comment
                    }, 
                    {
                        headers: {
                            'Content-Type': 'application/merge-patch+json'
                        }
                    })
                    .then(
                        (response) => {
                            if (response && response.status == 200) {
                                Utilities.successmsg("Le commentaire a bien été modifié !");
                                this.$emit('customer-updated');
                            }
                        },
                        () => {
                            Utilities.errormsg();
                        }
                    )
            }
        }
    };
</script>

<style lang="scss" scoped>
    .bx {
        cursor: pointer;
    }

    .bxs-star {
        color: #e5c100;
    }
</style>